<template>
  <div id="logis_inte">
    <div class="banner">
      <p>物流集成</p>
      <div>
        <span>物流中心规划</span>
        <span>智能装备集成</span>
      </div>
      <div>
        <span>物流网络规划</span>
        <span>运营诊断分析</span>
      </div>
    </div>
    <div class="container">
      <div class="floor_1 floor">
        <div class="floor1_title">
          <div class="floor1_t1">物流中心规划</div>
        </div>
        <div class="floor1_main">
          <div class="left">
            <div class="item">
              <p>经营模式 / 服务定位分析论证</p>
              <div>经营模式 / 服务定位分析论证</div>
              <!-- <div>经营模式 / 服务定位分析论证经营模式 / 服务定位分析论证</div> -->
            </div>
            <div class="item">
              <p>规模 / 需求 / 投资预算设计论证</p>
              <div>规模 / 需求 / 投资预算设计论证</div>
            </div>
            <div class="item">
              <p>运营数据分析 / 作业流程设计</p>
              <div>运营数据分析 / 作业流程设计</div>
            </div>
            <div class="item">
              <p>功能布局、动线设计与仿真</p>
              <div>功能布局、动线设计与仿真</div>
            </div>
          </div>
          <div class="right">
            <img src="~assets/image/logisInte/f1_1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_2 floor">
        <div class="floor2_title">
          <div class="floor2_t1">智能装备集成</div>
        </div>
        <div class="floor2_main">
          <div class="left">
            <img src="~assets/image/logisInte/f2_1.png" alt="" />
          </div>
          <div class="right">
            <div class="item">
              <p>场景分析与自动化集成规划</p>
              <div>场景分析与自动化集成规划</div>
            </div>
            <div class="item">
              <p>定制化设备设计研发与制造</p>
              <div>定制化设备设计研发与制造</div>
            </div>
            <div class="item">
              <p>工程验收服务、维保服务</p>
              <div>工程验收服务、维保服务</div>
            </div>
            <div class="item">
              <p>采购咨询服务、施工监理</p>
              <div>采购咨询服务、施工监理</div>
            </div>
          </div>
        </div>
      </div>
      <div class="floor_3 floor">
        <div class="floor3_title">
          <div class="floor3_t1">网络规划布局</div>
        </div>
        <div class="floor3_main">
          <div class="left">
            <div class="item">
              <p>物流网络结构与运作模式规划</p>
              <div>物流网络结构与运作模式规划</div>
            </div>
            <div class="item">
              <p>网络选址、场地规划、路由规划设计</p>
              <div>网络选址、场地规划、路由规划设计</div>
            </div>
            <div class="item">
              <p>干支线网、终端线路及多主体协同模式设计</p>
              <div>干支线网、终端线路及多主体协同模式设计</div>
            </div>
            <div class="item">
              <p>组织架构、管理流程、作业流程设计</p>
              <div>组织架构、管理流程、作业流程设计</div>
            </div>
            <div class="item">
              <p>网络运作过程监管与主动风险防范机制设计</p>
              <div>网络运作过程监管与主动风险防范机制设计</div>
            </div>
          </div>
          <div class="right">
            <img src="~assets/image/logisInte/f3_1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_4 floor">
        <div class="floor4_title">
          <div class="floor4_t1">运营管理咨询</div>
        </div>
        <div class="floor4_main">
          <div class="left">
            <img src="~assets/image/logisInte/f4_1.png" alt="" />
          </div>
          <div class="right">
            <div class="item">
              <p>物流项目上线运营辅导</p>
              <div>物流项目上线运营辅导</div>
            </div>
            <div class="item">
              <p>运营质量与作业效益分析诊断</p>
              <div>运营质量与作业效益分析诊断</div>
            </div>
            <div class="item">
              <p>作业流程、作业方式诊断优化</p>
              <div>作业流程、作业方式诊断优化</div>
            </div>
            <div class="item">
              <p>绩效管理优化/运作管理优化</p>
              <div>绩效管理优化/运作管理优化</div>
            </div>
            <div class="item">
              <p>管理与操作人员培训</p>
              <div>管理与操作人员培训</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisIntegra",
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
#logis_inte {
  width: 100%;
  font-family: Microsoft YaHei;
  .banner {
    position: relative;
    width: 100%;
    height: 3.62rem;
    padding: 0.92rem 0 0 3.5rem;
    background: #ffffff;
    background: url("~assets/image/logisInte/b_1.png") no-repeat;
    background-size: cover;
    p {
      font-size: 0.36rem;
      font-weight: 600;
      color: #525252;
    }
    div {
      font-size: 0.16rem;
      font-weight: 400;
      color: #838383;
      line-height: 0.24rem;
      span {
        margin-right: 0.44rem;
      }
    }
    div:nth-child(2) {
      margin-top: 0.3rem;
    }
  }
  .container {
    [class^="floor_"] {
      width: 100%;
      padding-top: 1.22rem;
      [class$="_title"] {
        font-size: 0.4rem;
        font-weight: bold;
        color: #383838;
        [class$="_t1"] {
          font-size: 0.4rem;
          font-weight: bold;
          color: #232323;
          text-align: center;
        }
      }
      [class$="_main"] {
        display: flex;
        // justify-content: center;
        height: 4.61rem;
        margin-top: 0.83rem;
        margin-left: 4.5rem;
      }
    }
    .floor_1,
    .floor_3 {
      .floor1_main,
      .floor3_main {
        .left {
          .item {
            margin-bottom: 0.25rem;
            p {
              font-size: 0.16rem;
              font-weight: bold;
              color: #333333;
              margin-bottom: 0.13rem;
            }
            div {
              font-size: 0.14rem;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .right {
          margin-top: -0.6rem;
          img {
            width: 7.8rem;
            height: 4.62rem;
            margin-left: 0.8rem;
          }
        }
      }
    }
    .floor_2,
    .floor_4 {
      background: #f7f7f7;
      .floor2_main,
      .floor4_main {
        .right {
          margin-top: -0.25rem;
          .item {
            margin-bottom: 0.35rem;
            p {
              font-size: 0.16rem;
              font-weight: bold;
              color: #333333;
              margin-bottom: 0.13rem;
            }
            div {
              font-size: 0.14rem;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .left {
          margin-top: -0.6rem;
          margin-left: -0.5rem;
          img {
            width: 7.8rem;
            height: 4.62rem;
          }
        }
      }
      .floor4_main {
        .right {
          margin-left: -0.4rem;
        }
      }
    }
  }
}
</style>